import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import BaseLayout from '../components/layout/base-layout';
import CatalogHeader from '../components/common/catalog-header';
import PageColumn from '../components/layout/page-column';
import RainbowBar from '../components/common/rainbow-bar';
import Section from '../components/layout/section'
import TaxonomyGrid from '../components/common/taxonomy/grid';

export default (props) => {
  let publisherNodes = props.data.publishers.edges.map(({ node }) => node);
  publisherNodes = publisherNodes.filter(publisher => {
    let bookNodes = publisher.data.Books || [];
    const publishedBookNodes = bookNodes.filter(book => book.data.Published);

    return publishedBookNodes.length > 0;
  });

  return (
    <BaseLayout>
      <Helmet>
        <title>Browse Cookbooks by Publisher</title>
        <meta
          property="og:title"
          content="Browse Cookbooks by Publisher"
        />
      </Helmet>
      <CatalogHeader
        label="Browse Cookbooks by Publisher"
      />
      <RainbowBar width={'thin'} />
      <Section topSpacing="section" bottomSpacing="section">
        <PageColumn>
          <TaxonomyGrid
            nodes={ publisherNodes }
            labelProperty='Name'
            pathStem='/publisher'
          />
        </PageColumn>
      </Section>
    </BaseLayout>
  );
};

export const query = graphql`
{
  publishers:allAirtable(filter:{table:{eq:"Publishers"}},
                sort:{fields: data___Name, order:ASC}) {
    edges {
      node {
        data {
          Name
          Slug
          Books {
            data {
              Slug
              Published
            }
          }
        }
      }
    }
  }
}
`;
